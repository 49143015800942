
// @ts-ignore
import _ from 'lodash-es';

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { linkDeviceWithVehicle, getVehicles } from '@/services/axios';

import Modal from '@/components/Modal.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';

@Component({
  components: {
    Modal,
    SectionH1,
  },
})
export default class DeviceNodeVehicleLinkView extends Vue {
  // TODO: any[] -> Vehicle[]
  @Prop(Number) readonly userId!: number;
  @Prop() readonly previousVehicle!: any;

  @State operators!: any[];
  @State opId!: number;

  isInit: boolean = true;

  vehicles: any = null;

  selectedVehicleIndex: number = 0;
  selectedOperator: any = null;

  @Watch('selectedOperator')
  onSelectedOperatorChanged() {
    this.selectedVehicleIndex = 0;
    this.loadVehicles();
  }

  loadVehicles() {
    if (this.selectedOperator) {
      getVehicles(this.selectedOperator.op_id)
        .then((res: any) => {
          this.vehicles = _.filter(res.data, (vhc: any) => {
            return vhc.is_mon === false;
          });

          if (this.isInit) {
            let indexes = _.keys(_.pickBy(this.vehicles, { vhc_id: this.previousVehicle.vhc_id }));
            if (indexes.length > 0) {
              this.selectedVehicleIndex = parseInt(indexes[0]) + 1;
            }
          }
        })
        .catch((err: any) => {})
        .finally(() => {
          this.isInit = false;
        });
    }
  }

  closeModal() {
    this.$emit('close-modal');
  }

  linkVehicles() {
    linkDeviceWithVehicle(
      this.opId,
      {
        dvusr_vhc: [[this.userId, this.selectedVehicleIndex]],
      },
      this.selectedOperator.op_id
    ).then(() => {
      this.onVehicleChanged();
      this.closeModal();
    });
  }

  onVehicleChanged() {
    if (this.selectedVehicleIndex) {
      this.$emit('onVehicleChanged', this.vehicles[this.selectedVehicleIndex - 1]);
    }
  }

  setSelectedVehicleIndex() {
    if (this.previousVehicle) {
      this.selectedOperator = _.find(this.operators, { op_id: this.previousVehicle.op_id });
    } else {
      this.selectedVehicleIndex = 0;
    }
  }

  mounted() {
    this.setSelectedVehicleIndex();
  }
}

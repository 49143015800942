
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Mutation, State } from 'vuex-class';
import { ToggleButton } from 'vue-js-toggle-button';
import { DeviceUserConfig } from '@/types/index.d';
import { putDeviceUserConfig } from '@/services/axios';

/**
 * A component to set `no_loading_mode_enable` to a device. It must receive `DeviceUserConfig`
 * as a prop. This component provides toggle button to toggle `no_loading_mode_enable`. It will
 * PUT and wait for a result from the API. If it's 200, it will emit `onNoLoadingModeEnableChanged`
 * as well. Therefore, it's a job of the parent component to update the state.
 */
@Component({
  components: {
    ToggleButton,
  },
})
export default class DeviceNoLoadingModeSettings extends Vue {
  @Prop() readonly device!: DeviceUserConfig;

  @State showNoLoadingModeHint!: boolean;
  @Mutation setShowNoLoadingModeHint!: (showNoLoadingMode: boolean) => void;

  noLoadingModeEnable: boolean = this.device.no_loading_mode_enable;

  @Watch('device', { deep: true, immediate: true })
  onDeviceChange() {
    this.noLoadingModeEnable = this.device.no_loading_mode_enable;
  }

  get showHint(): boolean {
    return this.showNoLoadingModeHint;
  }

  closeNoLoadingModeHint() {
    this.setShowNoLoadingModeHint(false);
  }

  toggleNoLoadingMode(event: { value: boolean; srcEvent: any }) {
    const noLoadingModeEnable = !event.value;
    putDeviceUserConfig(this.device.op_id, this.device.usr_id, {
      no_loading_mode_enable: noLoadingModeEnable,
    }).then(response => {
      if (response.status === 200) {
        this.noLoadingModeEnable = noLoadingModeEnable;
        this.$emit('onNoLoadingModeEnableChanged', noLoadingModeEnable);
      }
    });
  }
}

<template>
  <div
    :style="{ display: value ? 'flex' : 'none' }"
    style="position: absolute; top: 0; left: 0; width: 100%;  background-color: rgba(186, 192, 221, 0.8); z-index: 10000; flex-direction: column; justify-content: center; align-items: center; overflow: auto;bottom: 0;right: 0;"
    @click="$emit('close')"
  >
    <div style="padding: 16px;" @click.stop="">
      <img v-if="src" :src="src" style="width: 100%; max-width: 900px; height: auto;" />
      <img
        v-else
        src="@/assets/placeholder.svg"
        style="width: 100%; max-width: 900px; height: auto;"
      />
    </div>
    <div style="padding: 16px;">
      <img src="@/assets/btn-download.svg" style="cursor: pointer;" @click.stop="download" />
    </div>
  </div>
</template>

<script>
import download from 'downloadjs';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: '',
    },
  },
  methods: {
    download() {
      download(this.src);
    },
  },
};
</script>

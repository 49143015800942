
// @ts-ignore
import _ from 'lodash-es';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';

import L from 'leaflet';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant';

import { putDeviceUserConfig } from '@/services/axios';

import { DeviceUserConfig } from '@/types/index.d';

import Modal from '@/components/Modal.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';

import { GOOGLE_MAPS_TOKEN } from '@/services/config';
import { mapConfig } from '@/services/maps';

import CoordinateUtils from '@/utils/coordinate';

@Component({
  components: {
    Modal,
    SectionH1,
    LMap,
    LMarker,
    LTileLayer,
    Vue2LeafletGoogleMutant,
  },
})
export default class DeviceRenameModal extends Vue {
  @Prop() readonly device!: DeviceUserConfig;

  @Action loadDevices!: () => any;

  deviceName: string = '';
  selectedMarker: any = null;
  previousSelectedMarker: any = null;
  isLocationSelecting: boolean = false;

  @Watch('device', { immediate: true, deep: true })
  onDeviceChanged() {
    this.selectedMarker = null;
    this.previousSelectedMarker;
    if (this.device.lat && this.device.lng) {
      this.selectedMarker = {
        lat: this.device.lat,
        lng: this.device.lng,
      };
      this.previousSelectedMarker = _.cloneDeep(this.selectedMarker);
    }
  }

  get mapCameraCenter() {
    if (this.selectedMarker) {
      return [this.selectedMarker.lat, this.selectedMarker.lng];
    }
    return [13.724043632799845, 100.52558898925783];
  }

  get markerIcon(): any {
    const svg =
      '<svg xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49"> <g fill="none" fill-rule="evenodd"> <path d="M0 0L48 0 48 48 0 48z" transform="translate(0 1)"/> <path fill="#4D6AFF" stroke="#FFF" stroke-linejoin="round" stroke-width="2" d="M24 24c2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4 1.8 4 4 4zm0-20c8.4 0 16 6.44 16 16.4 0 6.36-4.9 13.84-14.68 22.46-.76.66-1.9.66-2.66 0C12.9 34.24 8 26.76 8 20.4 8 10.44 15.6 4 24 4z" transform="translate(0 1)"/> </g> </svg>';
    const iconUrl = 'data:image/svg+xml;base64,' + btoa(svg);

    return L.icon({
      iconUrl: iconUrl,
      iconSize: [48, 49], // size of the icon
      iconAnchor: [25, 46], //changed marker icon position
      popupAnchor: [0, -36], //changed popup position
    });
  }

  get apiKey(): string {
    return GOOGLE_MAPS_TOKEN;
  }

  get mapOptions(): any {
    return mapConfig;
  }

  mounted() {
    this.deviceName = this.device.name;
  }

  closeModal(): void {
    this.$emit('close-modal');
  }

  updateDevice(): void {
    const preparedDevice = {
      name: this.deviceName,
      lat: _.get(this.selectedMarker, 'lat', null),
      lng: _.get(this.selectedMarker, 'lng', null),
    };

    putDeviceUserConfig(this.device.op_id, this.device.usr_id, preparedDevice)
      .then(this.loadDevices)
      .then(this.closeModal);
  }

  clearDeviceName() {
    this.deviceName = '';
  }

  setSelectedMarker(latlng: any) {
    if (latlng && latlng.lat && latlng.lng) {
      this.selectedMarker = {
        lat: parseFloat(this.get6FractionDigitsString(latlng.lat)),
        lng: parseFloat(this.get6FractionDigitsString(latlng.lng)),
      };
    }
  }

  addMarker(event: any) {
    this.setSelectedMarker(event.latlng);
  }

  onDragMarkerEnd(event: any) {
    this.setSelectedMarker(event.target._latlng);
  }

  openLocationSelectionModal() {
    this.isLocationSelecting = true;
  }

  discardLocationSelectionModal() {
    this.selectedMarker = _.cloneDeep(this.previousSelectedMarker);
    this.isLocationSelecting = false;
  }

  deleteLocation() {
    this.selectedMarker = null;
    this.previousSelectedMarker = null;
  }

  confirmLocation(): void {
    this.isLocationSelecting = false;
    this.previousSelectedMarker = _.cloneDeep(this.selectedMarker);
  }

  get6FractionDigitsString(number: number) {
    return CoordinateUtils.get6FractionDigitsString(number);
  }
}
